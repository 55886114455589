const Display = ({famiglia}) =>{

    return(<div className="cards">
        {famiglia.map(membro => membro.img === undefined ?  
        <div className={membro.tipo}>
            <h2>{membro.name}</h2>
            <h3>idade:{membro.idade}</h3>
        </div>
        :
        <div className={membro.tipo}>
            <h2>{membro.name}</h2>
            <h3>idade:{membro.idade}</h3>
            <img src={membro.img}/>
        </div>)}
   </div> )
}

export default Display