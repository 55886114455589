import { useState } from "react"

const Input= ({famiglia, setFamiglia}) =>{

    const [nome,setNome] = useState();
    const [idade,setIdade] = useState();
    const [tipo,setTipo] = useState();
    const [img,setImg] = useState();

    const handleClick = () =>{
setFamiglia([...famiglia,{name:nome,idade:idade,tipo:tipo,img:img}])
    }
    return(<>
    <input value={nome} onChange={(event) => setNome(event.target.value)} placeholder='Nome'/>
    <input value={idade} onChange={(event) => setIdade(event.target.value)} placeholder='Idade'/>
    <input value={tipo} onChange={(event) => setTipo(event.target.value)} placeholder='Chama'/>
    <input value={img} onChange={(event) => setImg(event.target.value)} placeholder='Coloque o endereço da imagem'/>
    <button onClick={handleClick}>Concluir</button>
    </>)
}

export default Input